import React from 'react'
import Layout from '../components/Layout'
import { BiPhone, BiMailSend, BiMap } from 'react-icons/bi'
import * as styles from '../styles/contact.module.css'

export default function Contact() {
    return (
        <Layout>
            <section className={styles.contact}>
                <div className="u-center-text u-margin-bottom-big">
                    <h2 className="heading-secondary">
                        Contact Apostille Express - Legalisation Services
                    </h2>
                </div>
                <div className="row">
                    <div className="col-1-of-3">
                        <div className="contact__box">
                            <h3 className="heading-tertiary u-margin-bottom-small">Head Office</h3>
                            <a href="tel:+448453889263">
                                <BiPhone />
                                0845 388 9263
                            </a>
                            <h3 className="heading-tertiary u-margin-bottom-small">After Hours Emergencies</h3>
                            <a href="tel:+447792829263">
                                <BiPhone />
                                0779 282 9263
                            </a>
                            <h3 className="heading-tertiary u-margin-bottom-small">General Queries</h3>
                            <a href="mailto:info@apostille-express.co.uk">
                                <BiMailSend />
                                info@apostille-express.co.uk
                            </a>
                            <h3 className="heading-tertiary u-margin-bottom-small">Location</h3>
                            <div className={styles.contact__location}>
                                <BiMap />
                                <p className={styles.contact__location__text}>
                                    Apostille Express <br/>
                                    106 Old Christchurch Road <br/>
                                    Bournemouth, Dorset <br/>
                                    BH1 1LR
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-2-of-3">
                        <iframe title="Apostille Express Location" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2525.8968185576537!2d-1.8770814481323126!3d50.72185337941305!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4873a1c37940df2d%3A0x1595bd64685c285!2s106%20Old%20Christchurch%20Rd%2C%20Bournemouth%20BH1%201LR!5e0!3m2!1sen!2suk!4v1618927280583!5m2!1sen!2suk" width="600" height="450" allowfullscreen="" loading="lazy"></iframe>
                    </div>
                </div>
            </section>
        </Layout>
    )
}
